<template>
  <div class="text-left">
    <BaseHeader :title="'File Types'"></BaseHeader>

    <BaseLoading style="min-height: 400px;height: 90vh;" v-if="loading"></BaseLoading>

    <section v-else>
      <div class=" mt-2 ">
        <div class="card text-right d-flex align-items-center flex-row w-100 justify-content-between p-3" style>
          <base-select-site @siteSelected="getFileTypes"></base-select-site>
          <v-spacer></v-spacer>
          <button
            color="primary"
            class="my-auto btn btn-add ml-2"
            size="small"
            @click.stop="crudAction"
          >New File Type</button>
        </div>
        <!-- datatable -->
        <div  class="d-grid px-4 pb-4" v-if="fileTypes && fileTypes.length > 0">
          <v-chip
            outlined
            class="is-role-chip p-2 m-1"
            v-for="(file, index) in fileTypes"
            :key="index + 'typ'"
            color="teal"
            style="cursor: pointer"
            @click="editMode(file)"
          >
            .{{ file.type || '' }}
            <v-avatar
              color="teal"
              right
              is-role-trash-icon
              pa-1
            >
              <v-icon class="white--text">mdi-pencil</v-icon>
            </v-avatar>
          </v-chip>
        </div>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="fileTypesForm.busy">
          <v-card-title class="text-h5">{{ edit? "Update file type": "Add FileType(s)" }}</v-card-title>
          <v-card-text>
            <!--  form -->
            <form class="flex-fill text-left" ref="fileTypesForm" @submit.prevent="createFileType">
              <div>
                <div class="mb-3">
                  <v-alert v-if="!edit" type="info">
                    Enter file types separated by comma ","
                    <br />E.g. docx, pdf, jpg
                  </v-alert>
                </div>

                <v-row>
                <v-col cols="12" md="8" v-if="edit">
                    <v-text-field
                      dense
                      outlined
                      label="File Types"
                      name="type"
                      type="type"
                      v-model="fileTypesForm.type"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="fileTypesForm"
                            class="v-messages theme--light error--text"
                            field="type"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="8" v-else>
                    <v-text-field
                      dense
                      outlined
                      label="File Types"
                      name="types"
                      type="types"
                      v-model="fileTypesForm.types"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="fileTypesForm"
                            class="v-messages theme--light error--text"
                            field="types"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="fileTypesForm.busy"
                    type="submit"
                    color="teal"
                    class="white--text"
                  >{{ edit ? 'Update' : 'Save' }}</v-btn>
                  <v-btn type="button" @click="$store.state.dialog = !$store.state.dialog">Close</v-btn>
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "FileTypes",
  computed: {
    ...mapState("filetypes", [
      "fileTypesForm",
      "loading",
      "fileTypes",
    ]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("filetypes", ["getFileTypes"]),
    // ...mapActions("clients", ["getClients"]),
    createFileType() {
      this.edit
        ? this.fileTypesForm
            .put(
              `${this.$baseUrl}/Setups/FileTypes/${this.$store.state.site_id}`
            )
            .then((res) => {
              this.$store.state.dialog = false;
              

              this.$swal({
                icon: 'success',
                title: 'Success',
                text:`FileType updated successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.getFileTypes();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating FileType`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            })
        : this.fileTypesForm
            .post(
              `${this.$baseUrl}/Setups/FileTypes/${this.$store.state.site_id}`
            )
            .then((res) => {
              this.$store.state.dialog = false;
              

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `FileType created successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.getFileTypes();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating FileType`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            });
    },

    editMode(file) {
        this.edit = true;
        this.fileTypesForm.reset();
        this.$store.state.dialog = true;
        Object.assign(this.fileTypesForm, { ...file });
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.fileTypesForm.reset();
        this.$store.state.dialog = true;
      } else {
       

        this.$swal({
              icon: 'error',
              title: 'Error',
              text: "Please select a site to proceed",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
      }
    },
  },
  async mounted() {
    // this.$store.state.site_id = this.$route.params.siteid;
    if (this.$store.state.site_id) {
      await this.getFileTypes();
    }
  },
};
</script>